import React, { Component, Fragment, createRef } from 'react';
import { compose, graphql, withApollo } from 'react-apollo';
import { GET_ID_CONVOCATED, GET_DATA_REQUIRED_CABIFY, GET_USER_TRANSPORTATION_BY_CITY } from '../../../controllers/graphql/queries';
import CabifyView from './CabifyView';
import { getParameters } from '../../../controllers/graphql/queries';
import ServiceCabify from '../../../controllers/services/Cabify.services';
const cabify = new ServiceCabify;

class Cabify extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        userTransportation: '',
        nameEvent: '',
        eventValid: false,
        initialValues: {},
        loading: true,
        message: 'Oops! No esta autorizado para ingresar',
        dataEvent: {},
        transportationCurse: false,
        responseCabify: {},
        tokenCabify: null,
        datadriver: {},
        convocatedId: null
    }

    // la cotizacion del evento dfebe incluir alimentacion y transporte
    // el talento debe ser convocado para el evento al que aplica
    // el evento debe estar en estado abierto
    UNSAFE_componentWillMount = async () => {
        const { match, client } = this.props;
        console.log(" idTalent: ", match.params.idTalent);

        try {

            const idPermision = ['2bb7e38c-1b65-4b04-8408-8c31d75926ed', 'ea2913cd-e544-4afb-9f59-adfca5726ff9'];
            console.log("existe:" , idPermision.includes(match?.params?.idTalent));
            
            if(idPermision.includes(match?.params?.idTalent)){
                console.log("entra");
                
            }else{
                this.setState({
                    eventValid: false,
                    loading: false,
                    message: 'Módulo en desarrollo, estamos trabajando para tenerlo listo lo antes posible.'
                });
                return
            }

            const convocatedDb = await client.query({ //nos retorna un talento valido convocado para el evento
                query: GET_ID_CONVOCATED,
                variables: {
                    id: match.params.idConvocatory,
                    talentId: match.params.idTalent
                },
                fetchPolicy: 'network-only'
            });

            const convocatedId = convocatedDb?.data?.convocateds?.nodes[0]?.id || false;

            if (convocatedId) {

                const { data } = await client.query({
                    query: GET_DATA_REQUIRED_CABIFY,
                    variables: { id: convocatedId },
                    fetchPolicy: 'network-only'
                });

                console.log("data: ", data);
                const stateEvent = data?.convocated?.convocatory?.event?.stateId || 0;


                if (stateEvent == 36 || stateEvent == 37 || stateEvent == 38) { //EVENTO CANCELADO, FINALIZADO O RECHAZADO
                    this.setState({
                        eventValid: false,
                        loading: false,
                        message: 'Evento caducado.'
                    });
                    return;
                } else {
                    const userTransportation = await this.getUserTransportation('', data?.convocated?.convocatory?.event.cities?.nodes[0]?.id || 0);

                    if (userTransportation?.plataformTransportation?.userTransportations?.nodes.length == 0) {
                        this.setState({
                            eventValid: false,
                            message: 'Servicio no disponible para su ciudad',
                            loading: false,
                        });
                        return;
                    }

                    const initialValues = {
                        name: data?.convocated?.talent?.name ? `${data?.convocated?.talent?.name} ${data?.convocated?.talent?.lastname}` : '',
                        phoneClient: data?.convocated?.talent?.phone || '',
                        dialingCode: `${data?.convocated?.talent?.residenceCity?.country?.id}` || '',
                        origin: {
                            address: '',
                            coordinates: { lat: null, lng: null },
                        },
                        destiny: {
                            address: '',
                            coordinates: { lat: null, lng: null },
                        },
                        reasonJourney: `${data.convocated?.convocatory?.event?.quotation?.code || ''}`,
                        messajeToDriver: '',
                    };

                    // nos autenticamos con cabify:
                    const tokenCabify = await cabify.autenticationCabify();
                    console.log("tokenCabify: ", tokenCabify);

                    if (!tokenCabify) {
                        this.setState({
                            eventValid: false,
                            message: 'Error al autenticarse con cabify',
                            loading: false,
                        });
                        return
                    }
                    const countrie = await client.query({ query: getParameters });
                    console.log("userTransportation: ", userTransportation);

                    this.setState({
                        userTransportation: userTransportation.plataformTransportation,
                        convocatedId,
                        initialValues: initialValues,
                        eventValid: true,
                        loading: false,
                        message: 'Evento bueno.',
                        nameEvent: `${data?.convocated?.convocatory?.event?.name} (${data.convocated?.convocatory?.event?.quotation?.code})`,
                        dataEvent: data.convocated,
                        tokenCabify,
                        countries: countrie.data.countries.list,
                    })
                }

            } else {
                this.setState({
                    eventValid: false,
                    message: 'Servicio de transporte no disponible para este talento.',
                    loading: false,
                })
            }

        } catch (error) {
            console.log("error: ", error);
            this.setState({
                eventValid: false,
                loading: false,
                message: 'Servicio de transporte no disponible...',
            })
        }
    }

    // metodo para obtner el id autorizado para el tipo de plataforma por ciudad
    getUserTransportation = async (idPlatfomr, city) => {
        console.log("city: ", city);

        const { client } = this.props;

        try {
            const { data } = await client.query({
                query: GET_USER_TRANSPORTATION_BY_CITY,
                // TODO, por ahora el id es quemado el de cabify, mas adelante se debe ahcer un select del tipo de plataforma y se le pasa el id a esa consulta
                variables: { id: '2a652b03-b149-41c1-8fb0-5ee3bd1d9d8e', city: 57006 },
                fetchPolicy: 'network-only'
            });
            return data;
        } catch (error) {
            this.setState({
                eventValid: false,
                message: 'Error al autenticarse con cabify',
                loading: false,
            });
        }
    }

    changepoints = async (values, setFieldValue) => {
        const newOrigin = { ...values.destiny };
        const newDestiny = { ...values.origin };

        // Usamos setFieldValue para actualizar los valores de Formik
        setFieldValue('origin', newOrigin);
        setFieldValue('destiny', newDestiny);
    }

    // zona de ppruebas: https://www.google.com.ar/maps/@40.4316827,-3.68653,14.25z?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D
    submit = async (values) => {
        console.log("valores a mandar: ", values);
        const { dataEvent, tokenCabify, convocatedId, userTransportation } = this.state;
        console.log("data complementaria: ", dataEvent);

        try {
            let dataTosend = {
                // datos pasajero
                rider: {
                    mobile: { mobile_cc: `${values.dialingCode}`, mobile_num: `${values.phoneClient}` },
                    email: `${dataEvent.talent.email}`,
                    //id: null, //se manda nulo porque no esta vinculado a la empresa
                    locale: 'ES', //idioma
                    name: `${values.name}`
                },
                // datos del viaje / evento
                stops: [
                    {//inicial
                        loc: [values.origin.coordinates.lat, values.origin.coordinates.lng], //geolocalizacion
                        addr: `${values.origin.address}`, //direccion de recogida
                        city: `${dataEvent.convocatory.event.cities.nodes[0].name}`,
                        country: `${dataEvent.convocatory.event.cities.nodes[0].country.name}`,
                        // name: 'Jhon Doe', //nombre corto del lugar
                        // num: '1428' //numero de calle del lugar
                    },
                    {//final
                        loc: [values.destiny.coordinates.lat, values.destiny.coordinates.lng],
                        addr: `${values.destiny.address}`,
                        city: `${dataEvent.convocatory.event.cities.nodes[0].name}`,
                        country: 'ES',
                        // name: 'Jhon Doe', //nombre corto del lugar
                        // num: '1428' //numero de calle del lugar
                    }
                ],
                label_slug: null,
                message: `${values.messajeToDriver}`, //mensaje asociado al viaje
                product_id: '75dd566797369d1f0927102e5356ce59', //id del tipo de carro
                reason: `${values.reasonJourney}`,
                requester_id: `${userTransportation.userTransportations.nodes[0].id.replace(/-/g, '')}`, //user.id del usuario registrado en la cuenta
            }

            console.log("dataTosend", dataTosend);

            //TODO :descomentar
            const responseCabify = await cabify.createJourney(dataTosend, tokenCabify);
            console.log("responseCabify:", responseCabify);
            // if (responseCabify.journey_id) {

            if (true) { //TODO : quitar el true cuando estemos en produccion
                // si la solicitud de transporte es exitosa:
                // 1. habilitamos el boton para cancelar el transporte
                // 2. consultamos la solicitud

                //TODO :descomentar
                // const datadriver = await cabify.getJourney(responseCabify.journey_id, process.env.REACT_APP_USER_CABIFY, tokenCabify);

                const datadriver_prueba = {
                    "driver": {
                        "id": "e8417e8cd14c11ec869eb2e3f32e3b38",
                        "name": "Jhon Doe",
                        "phone_number": "34611111111"
                    },
                    "journey_id": "f0397896-19aa-11ed-b6fe-7aaea8067492",
                    "vehicle": {
                        "plate": "QXJ4758",
                        "name": "Seat ibiza",
                        "color": "red",
                    },
                    "waypoints": {
                        "rows": [
                            {
                                "loc": [
                                    3.41856147316953,
                                    -76.51397169190064
                                ],
                                "state_name": "hired"
                            }
                        ],
                        "total": 48000
                    }
                }
                // DATA QUE LE MANDO AL BACKEND PARA QUE GUARDE EN BASE DE DATOS EL REGISTRO DEL VIAJE
                const dataSuscription = {
                    journey_id: '',
                    convocatedId,
                    values: {
                        baseService: datadriver_prueba.waypoints.total,
                        iva: 19,
                        feeCabify: 19,
                    },
                    talent: {
                        name_person: `${values.name}`,
                        phone_service: { mobile_cc: `${values.dialingCode}`, mobile_num: `${values.phoneClient}` },
                    },
                    journey: {
                        origin: {
                            loc: [values.origin.coordinates.lat, values.origin.coordinates.lng],
                            addr: `${values.origin.address}`,
                        },
                        destiny: {
                            loc: [values.destiny.coordinates.lat, values.destiny.coordinates.lng],
                            addr: `${values.destiny.address}`,
                        }
                    }
                }

                await this.suscriptionJourney(dataSuscription);

                this.setState({
                    transportationCurse: true,
                    // responseCabify, //TODO :descomentar
                    datadriver: datadriver_prueba
                });

            }

        } catch (error) {
            console.log("error: ", error);
        }
    }

    // metodo para sucribirse al back y esperar si hay un emit del get journey detail
    suscriptionJourney = (data) => {
        try {

        } catch (error) {
            console.log("error: ", error);
        }
    }

    cancelJourney = async () => {
        try {
            // TODO : descomentar
            const { responseCabify, tokenCabify } = this.state;
            const cancelJourney = await cabify.cancelJourney(tokenCabify, responseCabify.journey_id);
            console.log("respuesta al cancelar viaje: ", cancelJourney);

            this.setState({
                transportationCurse: false,
                responseCabify, //TODO :descomentar
                datadriver: {}
            })
        } catch (error) {
            console.log("error: ", error);
        }
    }

    render() {
        const { loading, message, initialValues, eventValid, nameEvent, transportationCurse, datadriver, countries } = this.state;
        if (loading) return 'Cargando..';

        return (
            <Fragment>
                <CabifyView
                    changepoints={this.changepoints}
                    datadriver={datadriver}
                    countries={countries}
                    cancelJourney={this.cancelJourney}
                    transportationCurse={transportationCurse}
                    nameEvent={nameEvent}
                    eventValid={eventValid}
                    loading={loading}
                    message={message}
                    initialValues={initialValues}
                    submit={this.submit}
                ></CabifyView>
            </Fragment>
        )
    }
}

export default compose(
    withApollo,
    // graphql(SAVE_URL_CONTRACT, { name: 'updateUrl' }),
)(Cabify);