import gql from 'graphql-tag';
import { object } from 'prop-types';

const createTalent = gql`
  mutation{
    createTalent(
      input: {
        talent: {
          name: "Carolina"
          lastname: "Gomez"
          email: "carolina@gomez.com"
          twitter: "CarolinaGomez96"
        }
      }
    ){
      talent{
        id
        name
      }
    }
  }
`;

const registerFast = gql`
  mutation(
    $nameUser: String
    $lastnameUser: String
    $cityUser: Int
    $typeIdUser: Int
    $identificationUser: String
    $phoneUser: String
    $emailUser: String
    $stateUser: Int
    $nameAgency: String
    $cityAgency: Int
    $typeIdAgency: Int
    $identificationAgency: String
    $phoneAgency: String
    $emailAgency: String
    $stateAgency: Int
    $legalNature: LegalNature
  ) {
    register(
      input: {
        nameAgency: $nameAgency
        cityAgency: $cityAgency
        typeIdentificationAgency: $typeIdAgency
        identificationAgency: $identificationAgency
        phoneAgency: $phoneAgency
        emailAgency: $emailAgency
        stateAgency: $stateAgency
        legalNature: $legalNature
        nameUser: $nameUser
        lastnameUser: $lastnameUser
        cityUser: $cityUser
        typeIdentificationUser: $typeIdUser
        identificationUser: $identificationUser
        phoneUser: $phoneUser
        emailUser: $emailUser
        stateUser: $stateUser
      }
    ) {
      user {
        id
      }
      agency{
        id
      }
    }
  }
`

const updatePassword = (params, data) => {
  let cadena = `
    mutation {
      updateUser(input: {
        id: "${data.idUser}"
        patch: {
          password: "${data.password}",
          ${params.includes('/acti') ? `state: ${data.stateUser}` : ''} 
        }
      }){
        user{
          id
          name
          lastname
          state
          phone
          email
          agencies{
            list: nodes{
              id
              name
              state
              logo
              legalNature
            }
          }
        }
      }
      ${params.includes('/acti') ?
      `updateAgency(input: {
          id: "${data.idAgency}"
          patch: {
            state: ${data.stateAgency}
          }
        }){
          agency{
            id
            name
            legalNature
          }
        } `
      : ''}
    }
  `;

  // console.log(cadena)
  let object = gql`${cadena}`;
  return object;
}

const changePasswordTalent = (idTalent, newPassword) => {
  let cadena = `
    mutation changePasswordTalent {
      updateTalent(input: {
        patch: {password: "${newPassword}"}, 
        id: "${idTalent}"
      }) {
        clientMutationId
      }
    }  
  `;

  let object = gql`${cadena}`;
  return object;
}

//TODO: Hacer esto con una funcion o store procedure
const CREATECLIENT = gql`
  mutation(
    $name: String!
    $address: String
    $phone: String!
    $logo: String
    $typeIdentificationId: Int!
    $identification: String!
    $state: Int
    $cityId: Int!
    $nameProducer: String!
    $phoneProducer: String!
    $emailProducer: String!
    $url: String
  ){
    crear: createClient(input: {
      client: {
        name: $name
        address: $address
        phone: $phone
        logo: $logo
        typeIdentificationId: $typeIdentificationId
        identification: $identification
        state: $state
        cityId: $cityId
        nameProducer: $nameProducer
        phoneProducer: $phoneProducer
        emailProducer: $emailProducer
        url: $url
      }
    }){
      client{
        id
        name
      }
    }
  }
`;

const CREATESERVICESCLIENT = (idClient, services) => {
  let cadena = `
  mutation{
    ${services.map((service, index) => `
      createService${index}: createServiceClient(
        input: {
          serviceClient: {
            clientId: "${idClient}"
            serviceId: ${service.value}
          }
        }
      ){
        serviceClient {
          id
        }
      } 
    `)}
  }
  `

  let object = gql`${cadena}`;
  return object;
}

const CREATEAGENCYCLIENT = gql`
  mutation(
    $idAgency: UUID
    $idClient: UUID
  ){
    createAgencyClient(input: {
      agencyClient: {
        agencyId: $idAgency
        clientId: $idClient
      }
    }){
      agencyClient{
        id
      }
    }
  }
`;

const UPDATE_STATE_CLIENT = gql`
  mutation(
    $id: UUID!
    $state: Int
  ){
    update: updateClient(input: {
      id: $id
      patch: {
        state: $state
      }
    }){
      client{
        id
      }
    }
  }
`;

const handleUpdateClient = (client, producer, pathLogo) => {
  let cadena = `
    mutation{
      deleteserviceclientbyclientid(input: {
        clientid: "${client.id}"
      }){
        clientMutationId
      }

      updateClient(input: {
        id: "${client.id}"
        patch: {
          name: "${client.name}"
          address: ${client.address === '' ? null : `"${client.address}"`}
          phone: "${client.phone.replace(/[()' '-]/g, '')}"
          logo: ${!pathLogo ? null : `"${pathLogo}"`}
          typeIdentificationId: ${Number(client.typeIdentification)}
          identification: "${client.identification}"
          state: ${client.state ? 52 : 53}
          cityId: ${Number(client.city)}
          url: ${client.url === '' ? null : `"${client.url}"`}
          nameProducer:  "${producer.name}"
          phoneProducer: "${producer.phone.replace(/[()' '-]/g, '')}"
          emailProducer: "${producer.email}"
        }
      }){
        client{
          id
        }
      }
      ${client.services.map((service, index) => `
      createServiceClient${index}: createServiceClient(input:{
        serviceClient: {
          clientId: "${client.id}"
          serviceId: ${Number(service.value)}
        }
      }){
        serviceClient{
          id
        }
      }
    `)}
  }
`
  console.log(cadena)
  let object = gql`${cadena}`;
  return object;
}

const CREATE_CLIENT_FAST = gql`
  mutation(
    $name: String
    $phone: String
    $typeIdentification: Int
    $identification: String
    $state: Int
    $cityId: Int
    $nameProducer: String
    $phoneProducer: String
    $emailProducer: String
    $agencyId: UUID
  ){
    createclientfast(input: {
      name: $name
      phone: $phone
      typeidentification: $typeIdentification
      identification: $identification
      state: $state
      cityid: $cityId
      nameproducer: $nameProducer
      phoneproducer: $phoneProducer
      emailproducer: $emailProducer
      agencyid: $agencyId
    }){
      client{
        id
      }
    }
  }
`;

const CREATE_EVENT_DIRECTORY_WITH_PLACE = gql`
  mutation(
    $name: String
    $start: Datetime
    $end: Datetime
    $logoEvent: String
    $place: String
    $address: String
    $cityId: Int
    $logoPlace: String
  ){
    createeventdirectoryfast(input: {
      nameevent: $name
      startdate: $start
      enddate: $end
      logoevent: $logoEvent,
      nameplace: $place
      addressplace: $address
      cityplace: $cityId
      logoplace: $logoPlace
    }){
      eventDirectory{
        id
      }
    }
  }
`;

const CREATE_EVENT_DIRECTORY = gql`
  mutation(
    $name: String
    $start: Datetime
    $end: Datetime
    $placeDirectoryId: UUID
    $logoEvent: String
  ){
    createEventDirectory(input: {
      eventDirectory: {
        name: $name
        startDate: $start
        endDate: $end
        placeDirectoryId: $placeDirectoryId
        logo: $logoEvent
      }
    }){
      eventDirectory{
        id
      }
    }
  }
`;


const CREATE_EVENT = gql`
  mutation(
    $name: String
    $description: String
    $stateId: Int
    $startDate: Datetime
    $endDate: Datetime
    $eventDirectory: UUID
    $totalDays: Int
    $totalEvent: Int
    $logo: String
    $quotationId: UUID
  ){
    createEvent(input: {
      event: {
        name: $name
        description: $description
        stateId: $stateId
        startDate: $startDate
        endDate: $endDate
        eventDirectoryId: $eventDirectory
        totalDays: $totalDays
        totalEvent: $totalEvent
        logo: $logo
        quotationId: $quotationId
      }
    }){
      event{
        id
        name
      }
    }
  }
`

// const CREATE_EVENT_AGENCY_CLIENT = gql`
//   mutation(
//     $eventId: UUID
//     $agencyId: UUID
//     $clientId: UUID
//   ){
//     createEventAgencyClient(input: {
//       eventAgencyClient: {
//         eventId: $eventId
//         agencyId: $agencyId
//         clientId: $clientId
//       }
//     }){
//       eventAgencyClient{
//         id
//       }
//     }
//   }
// `;

const CREATE_EVENT_PLACE = gql`
  mutation(
    $eventId: UUID
    $stateId: Int
  ){
    createEventPlace(input: {
      eventPlace: {
        eventId: $eventId
        stateId: $stateId
      }
    }){
      eventPlace{
        id
      }
    }
  } 
`;

const handleCreateEventPersonal = (data, eventId) => {
  let cadena = `
    mutation{
      ${data.map((item, index) => {
    let { talent, startDate, endDate } = item
    return `createEventPersonal${index}: createEventPersonal(input: 
          {
            eventPersonal: {
              startDate: "${startDate}"
              endDate: "${endDate}"
              quantity: ${Number(talent.quantity)}
              gender: ${talent.gender.toUpperCase()}
              profile: ${talent.typeTalent.toUpperCase()}
              budget: ${Number(talent.budget)}
              eventId: "${eventId}"
            }
          })
          {
            eventPersonal{
              id
            }
          } 
        `
  })}
    }
  `;

  return cadena;
}

const DELETE_EVENT_PERSONAL = gql`
  mutation($id: UUID ){
    deleteeventpersonalbyeventid(input: {
      eventid: $id
    }){
      clientMutationId
    }
}
`

const UPDATE_EVENT_IN_QUOTATION = gql`
  mutation(
    $id: UUID!
    $totalDays: Int
    $totalEvent: Int
    $description: String
  ){
    updateEvent(input: {
      id: $id
      patch: {
        totalEvent: $totalEvent
        totalDays: $totalDays
        description: $description
      }
    }){
      event{
        id
      }
    }
  }
`

const EDIT_EVENT_WITH_PLACE = gql`
  mutation(
    $eventId: UUID
    $description: String
    $startDate: Datetime
    $endDate: Datetime
    $logoEvent: String
    $url: String
    $placeDirectoryId: UUID
    $stateId: Int
  ){
    editeventwithplace(input: {
      eventid: $eventId
      descriptionevent: $description
      startdate: $startDate
      enddate: $endDate
      logoevent: $logoEvent
      urlevent: $url
      placeid: $placeDirectoryId
      stateid: $stateId
    }){
      event{
        id
      }
    }
  }
`;

const EDIT_EVENT_CREATE_PLACE = gql`
  mutation(
    $eventId: UUID
    $description: String
    $startDate: Datetime
    $endDate: Datetime
    $logoEvent: String
    $url: String
    $namePlace: String
    $addressPlace: String
    $logoPlace: String
    $cityId: Int
    $stateId: Int
  ){
    editeventcreateplace(input: {
      eventid: $eventId
      descriptionevent:  $description
      startdate: $startDate
      enddate: $endDate
      logoevent: $logoEvent
      urlevent: $url
      nameplace: $namePlace
      addressplace: $addressPlace
      logoplace: $logoPlace
      cityid: $cityId
      stateid: $stateId 
    }){
      event{
        id
      }
    }
  }
`

const handleCreateConvocateds = (talents, stateId, announcementId) => {
  let cadena = `
    mutation{
      ${talents.map((talent, index) => `
        create${index}: createConvocated(input: {
          convocated: {
            talentId: "${talent.id}"
            stateId: ${stateId}
            convocatoryId: "${announcementId}"
          }
        }){
          convocated{
            id
          }
        }
      `)}
    }
  `;

  // console.log(cadena)
  let object = gql`${cadena}`;
  return object;
}

const CREATE_CONVOCATORY_EVENT = gql`
  mutation(
    $eventId: UUID!
    $colourEyes: String
    $colourHair: String
    $height: String
    $unitMeasure: UnitMeasure
    $texture: String
    $language: String
    $cityId: Int
    $skills: String
    $academicTraining: String
    $observations: String
    $startDate: Datetime
    $endDate: Datetime
    $stateId: Int
    $code: String
    $typeTalent: UUID
  ){
    createConvocatory(input: {
      convocatory: {
        eventId: $eventId
        colourEyes: $colourEyes
        colourHair: $colourHair
        height: $height
        unitMeasure: $unitMeasure
        texture: $texture
        language: $language
        cityId: $cityId
        skills: $skills
        academicTraining: $academicTraining
        observations: $observations
        startDate: $startDate
        endDate: $endDate
        stateId: $stateId
        code: $code
        eventPersonalId: $typeTalent
      }
    }){
      convocatory{
        id
      }
    }
  }
`

const UPDATE_EVENT_STATE_AND_URL_KEY = gql`
  mutation(
    $eventId: UUID!, 
    $stateId: Int, 
    $urlKey: String,
    $finalClient: Boolean,
    $finalClientId: UUID
  ){
    updateEvent(input: {
      id: $eventId
      patch: {
        stateId: $stateId
        urlKey: $urlKey
        finalClientColumn: $finalClient
        finalClientId: $finalClientId
      }
    }){
      event{
        id
        stateId
      }
    }
  }
`

const UPDATE_FINAL_CLIENT_LOGO = gql`
  mutation(
    $id: UUID!, 
    $name: String,
    $logo: String 
  ){
    updateFinalClient(input: {
      id: $id
      patch: {
        name: $name
        urlLogo: $logo
      }
    }){
      finalClient {
        id
      }
    }
  }
`

const UPDATE_FINAL_CLIENT = gql`
  mutation(
    $id: UUID!, 
    $name: String, 
  ){
    updateFinalClient(input: {
      id: $id
      patch: {
        name: $name
      }
    }){
      finalClient {
        id
      }
    }
  }
`

const UPDATE_ANNOUNCEMENT = gql`
  mutation(
    $id: UUID!,
    $startDate: Datetime
    $endDate: Datetime
    $colourEyes: String
    $colourHair: String
    $height: String
    $unitMeasure: UnitMeasure
    $texture: String
    $language: String
    $cityId: Int
    $skills: String
    $academicTraining: String
    $observations: String
    $stateId: Int
  ){
    updateConvocatory(input: {
      id: $id
      patch: {
        startDate: $startDate
        endDate: $endDate
        colourEyes: $colourEyes
        colourHair: $colourHair
        height: $height
        unitMeasure: $unitMeasure
        texture: $texture
        language: $language
        cityId: $cityId
        skills: $skills
        academicTraining: $academicTraining
        observations: $observations
        stateId: $stateId
      }
    }){
      convocatory{
        id
      }
    }
  }
`;

const CREATE_ANNOUNCEMENT_FAST = gql`
  mutation(
    $start: Datetime, 
    $end: Datetime
    $eventId: UUID
    $stateId: Int
  ){
    createConvocatory(input: {
      convocatory: {
        startDate: $start
        endDate: $end
        eventId: $eventId
        stateId: $stateId
      }
    }){
      convocatory{
        id
      }
    }
  }
`;

const UPDATE_EVENT_FOR_ADDITIONAL_PROFILES = gql`
  mutation($id: UUID!, $additionalProfiles: String){
    updateEvent(input: {
      id: $id
      patch: {
        additionalProfiles: $additionalProfiles
      }
    }){
      event{
        id
      }
    }
  }
`;

const UPDATE_STATE_ANNOUNCEMENT = gql`
  mutation($id: UUID!, $stateId: Int) {
    updateConvocatory(input: {
      id: $id
      patch: {
        stateId: $stateId
      }
    }) {
      event {
        id
      }
    }
  }
`;

const UPDATE_STATE_EVENT = gql`
  mutation($id: UUID!, $stateId: Int){
    updateEvent(input: {
      id: $id
      patch: {
        stateId: $stateId
      }
    }){
      quotation {
        id
      }
    }
  }
`;

const UPDATE_QUOTATION_EVENT = gql`
  mutation($id: UUID!, $stateId: Int){
    updateQuotation(input: {
      id: $id
      patch: {
        stateId: $stateId
      }
    }){
      quotation {
        id
      }
    }
  }
`;

const UPDATE_STATE_CONVOCATED = gql`
  mutation($id: UUID!, $stateId: Int){
    updateConvocated(input: {
      id: $id
      patch: {
        stateId: $stateId
      }
    }){
      convocated{
        id
      }
    }
  }
`;

const CREATE_LEGAL_REPRESENTATIVE = gql`
  mutation(
    $idAgency: UUID!
    $documentLegalRepresentative: String
    $nameLegalRepresentative: String
    $cityLegalRepresentative: Int
  ){
  createLegalRepresentative(input: {
        legalRepresentative: {
          agencyId: $idAgency
          cityId: $cityLegalRepresentative
          document: $documentLegalRepresentative
          name: $nameLegalRepresentative
        }
      }
    ){
      agency {
        id
      }
    }
  }
`
// SE USA CUANDO HAY REPRESENTANTE LEGAL PARA ACTUALIZAR LA AGENCIA
const UPDATE_AGENCY_LOCATION_USER_LEGAL = (data, type) => {
  let cadena = `
  mutation{
    updateAgency(input: {
      id: "${data.idAgency}"
      patch: {
        ${data.hasOwnProperty(`emailAgency`) && data.emailAgency !== "" ? `email: "${data.emailAgency}"` : ''}
        ${data.hasOwnProperty(`phoneAgency`) && data.phoneAgency !== "" ? `phone: "${data.phoneAgency}"` : ''}
        ${data.hasOwnProperty(`logo`) && data.logo !== "" ? `logo: "${data.logo}"` : ''}
        ${data.hasOwnProperty(`url`) && data.url !== "" ? `url: "${data.url}"` : ''}
        ${data.hasOwnProperty(`addressAgency`) && data.addressAgency !== "" ? `address: "${data.addressAgency}"` : ''}
        ${type == "PERSONA_NATURAL" ? (data.hasOwnProperty(`cityIdUser`) && data.cityIdUser !== "" ? `cityId: ${data.cityIdUser}` : '') : (data.hasOwnProperty(`cityIdAgency`) && data.cityIdAgency !== "" ? `cityId: ${data.cityIdAgency}` : '')}
        ${data.hasOwnProperty(`gain`) && data.gain !== "" ? `gain: ${data.gain}` : ''}
        ${data.hasOwnProperty(`responsableIva`) && data.responsableIva !== "" ? `responsableIva: ${data.responsableIva}` : ''}
        ${data.hasOwnProperty(`personaNaturalArt383AEt`) && data.personaNaturalArt383AEt !== "" ? `personaNaturalArt383AEt: ${data.personaNaturalArt383AEt}` : ''}
        ${data.hasOwnProperty(`declaranteRenta`) && data.declaranteRenta !== "" ? `declaranteRenta: ${data.declaranteRenta}` : ''}
        ${data.hasOwnProperty(`granContribuyente`) && data.granContribuyente !== "" ? `granContribuyente: ${data.granContribuyente}` : ''}
        ${data.hasOwnProperty(`regimenSimple`) && data.regimenSimple !== "" ? `regimenSimple: ${data.regimenSimple}` : ''}
        ${data.hasOwnProperty(`autoretenedorRenta`) && data.autoretenedorRenta !== "" ? `autoretenedorRenta: ${data.autoretenedorRenta}` : ''}
        ${data.hasOwnProperty(`autoretenedorIca`) && data.autoretenedorIca !== "" ? `autoretenedorIca: ${data.autoretenedorIca}` : ''}
        ${data.hasOwnProperty(`payment`) && data.payment !== "" ? `payment: ${data.payment}` : ''}
      }
    }){
      agency{
        id
      }
    }

    updateLegalRepresentative( input: {
      id: "${data.idLegalRepresentative}"
      patch: {
        ${data.hasOwnProperty(`cityLegalRepresentative`) && data.cityLegalRepresentative !== "" ? `cityId: ${data.cityLegalRepresentative}` : ''}
        ${data.hasOwnProperty(`documentLegalRepresentative`) && data.documentLegalRepresentative !== "" ? `document: "${data.documentLegalRepresentative}"` : ''}
        ${data.hasOwnProperty(`nameLegalRepresentative`) && data.nameLegalRepresentative !== "" ? `name: "${data.nameLegalRepresentative}"` : ''}
        }
      }
    ){
      agency {
        id
      }
    }
    
    updateUser(input: {
      id: "${data.idUser}"
      patch: {
        ${data.hasOwnProperty(`nameUser`) && data.nameUser !== "" ? `name: "${data.nameUser}"` : ''}
        ${data.hasOwnProperty(`lastnameUser`) && data.lastnameUser !== "" ? `lastname: "${data.lastnameUser}"` : ''}
        ${data.hasOwnProperty(`phoneUser`) && data.phoneUser !== "" ? `phone: "${data.phoneUser}"` : ''}
        ${data.hasOwnProperty(`cityIdUser`) && data.cityIdUser !== "" ? `cityId: ${data.cityIdUser}` : ''}
        ${data.hasOwnProperty(`corporateEmail`) && data.corporateEmail !== "" ? `corporateEmail: "${data.corporateEmail}"` : ''}
        ${data.hasOwnProperty(`corporatePassword`) && data.corporatePassword !== "" ? `corporatePassword: "${data.corporatePassword}"` : ''}
        ${data.hasOwnProperty(`addressUser`) && data.addressUser !== "" ? `address: "${data.addressUser}"` : ''}
        ${data.hasOwnProperty(`idCurrency`) && data.idCurrency !== "" ? `currencyId: "${data.idCurrency}"` : ''}
      }
    }){
      user{
        id
      }
    }
  }
`;
  // console.log("cadena:", cadena);
  let object = gql`${cadena}`;
  return object;
}

// SE USA CUANDO NOOO HAY REPRESENTANTE LEGAL PARA ACTUALIZAR LA AGENCIA

const UPDATE_AGENCY_LOCATION_USER = (data, type) => {
  let cadena = `
    mutation{
      updateAgency(input: {
        id: "${data.idAgency}"
        patch: {
          ${data.hasOwnProperty(`emailAgency`) && data.emailAgency !== "" ? `email: "${data.emailAgency}"` : ''}
          ${data.hasOwnProperty(`phoneAgency`) && data.phoneAgency !== "" ? `phone: "${data.phoneAgency}"` : ''}
          ${data.hasOwnProperty(`logo`) && data.logo !== "" ? `logo: "${data.logo}"` : ''}
          ${data.hasOwnProperty(`url`) && data.url !== "" ? `url: "${data.url}"` : ''}
          ${data.hasOwnProperty(`addressAgency`) && data.addressAgency !== "" ? `address: "${data.addressAgency}"` : ''}
          ${type == "PERSONA_NATURAL" ? (data.hasOwnProperty(`cityIdUser`) && data.cityIdUser !== "" ? `cityId: ${data.cityIdUser}` : '') : (data.hasOwnProperty(`cityIdAgency`) && data.cityIdAgency !== "" ? `cityId: ${data.cityIdAgency}` : '')}
          ${data.hasOwnProperty(`gain`) && data.gain !== "" ? `gain: ${data.gain}` : ''}
          ${data.hasOwnProperty(`responsableIva`) && data.responsableIva !== "" ? `responsableIva: ${data.responsableIva}` : ''}
          ${data.hasOwnProperty(`personaNaturalArt383AEt`) && data.personaNaturalArt383AEt !== "" ? `personaNaturalArt383AEt: ${data.personaNaturalArt383AEt}` : ''}
          ${data.hasOwnProperty(`declaranteRenta`) && data.declaranteRenta !== "" ? `declaranteRenta: ${data.declaranteRenta}` : ''}
          ${data.hasOwnProperty(`granContribuyente`) && data.granContribuyente !== "" ? `granContribuyente: ${data.granContribuyente}` : ''}
          ${data.hasOwnProperty(`regimenSimple`) && data.regimenSimple !== "" ? `regimenSimple: ${data.regimenSimple}` : ''}
          ${data.hasOwnProperty(`autoretenedorRenta`) && data.autoretenedorRenta !== "" ? `autoretenedorRenta: ${data.autoretenedorRenta}` : ''}
          ${data.hasOwnProperty(`autoretenedorIca`) && data.autoretenedorIca !== "" ? `autoretenedorIca: ${data.autoretenedorIca}` : ''}
          ${data.hasOwnProperty(`payment`) && data.payment !== "" ? `payment: ${data.payment}` : ''}
        }
      }){
        agency{
          id
        }
      }
      
      updateUser(input: {
        id: "${data.idUser}"
        patch: {
          ${data.hasOwnProperty(`nameUser`) && data.nameUser !== "" ? `name: "${data.nameUser}"` : ''}
          ${data.hasOwnProperty(`lastnameUser`) && data.lastnameUser !== "" ? `lastname: "${data.lastnameUser}"` : ''}
          ${data.hasOwnProperty(`phoneUser`) && data.phoneUser !== "" ? `phone: "${data.phoneUser}"` : ''}
          ${data.hasOwnProperty(`cityIdUser`) && data.cityIdUser !== "" ? `cityId: ${data.cityIdUser}` : ''}
          ${data.hasOwnProperty(`corporateEmail`) && data.corporateEmail !== "" ? `corporateEmail: "${data.corporateEmail}"` : ''}
          ${data.hasOwnProperty(`corporatePassword`) && data.corporatePassword !== "" ? `corporatePassword: "${data.corporatePassword}"` : ''}
          ${data.hasOwnProperty(`addressUser`) && data.addressUser !== "" ? `address: "${data.addressUser}"` : ''}
          ${data.hasOwnProperty(`idCurrency`) && data.idCurrency !== "" ? `currencyId: "${data.idCurrency}"` : ''}
        }
      }){
        user{
          id
        }
      }
    }
  `;

  // console.log("cadena:", cadena);
  let object = gql`${cadena}`;
  return object;
}


//Cerrar Convocatoria
const handleChangeStatesConvocateds = convocateds => {
  let cadena = `
    mutation{
      ${convocateds.map((convocated, index) => `
        updateConvocated${index}: updateConvocated(input: {
          id: "${convocated.id}"
          patch: {
            stateId: 1813
          }
        }){
          convocated{
            id
          }
        } 
      ` )}
    }
  `

  // console.log(cadena)
  let object = gql`${cadena}`;
  return object;
}

const handleCreatePriceTaxation = (idCity, dataToCreate) => {
  let cadena = `
    mutation{
      ${dataToCreate.map((item, index) => `
        create${index}: createPricesTaxation(input: {
          pricesTaxation: {
            cityId: ${idCity}
            taxationId: ${item.taxationId}
          }
        }){
          pricesTaxation{
            id
          }
        }
      `)}
    }
  `;

  // console.log(cadena)
  let object = gql`${cadena}`;
  return object;
}

const handleCreateTaxationAgency = (idAgency, taxations) => {
  let cadena = `
    mutation{
      ${taxations.map((taxation, index) => `
        create${index}: createAgencyPricesTaxation(input: {
          agencyPricesTaxation: {
            agencyId: "${idAgency}"
            pricesTaxationId: "${taxation.idPriceTaxation}"
          }
        }){
          agencyPricesTaxation{
            id
          }
        }
      `)}
    } 
  `;

  let object = gql`${cadena}`;
  return object;
}

const handleCreateFinalCLient = (finalClientName, url) => {
  let cadena = `
    mutation{
      create: createFinalClient(input: {
        finalClient: {
          name: "${finalClientName}"
          urlLogo: "${url}"
        }
      }){
        finalClient {
          id
        }
      }
    } 
  `
  let object = gql`${cadena}`;
  return object;
}

const deleteAgencyPricesTaxation = (taxList) => {
  let cadena = `
    mutation{
      ${taxList.map((id, index) => `
        deleteAgencyPricesT${index}: deleteAgencyPricesTaxation(input: {
          id: "${id.id}"
        }) {
          clientMutationId
          deletedAgencyPricesTaxationNodeId
        }
      `).join('')}
    }
  `;

  let object = gql`${cadena}`;
  return object;
}

const handleCreateQuotation = (dataQuote, taxations, idAgency, idQuote, idState, processCode) => {
  // (data, billsByTalent, totals, taxations, idAgency, idQuote, idState, processCode)
  // const { dataClient, parametersQuotation, talents } = data;
  const { data, dataClient, parametersQuotation, feeding, transport } = dataQuote;
  const { quote: { talents, billsByTalent, totals } } = data[0];

  let cadena = `
    mutation{
      updateAgency(input: {
        id: "${idAgency}"
        patch:{
          consecutiveQuote: ${processCode.newConsecutiveCode}
        }
      }){
        agency{
          id
        }
      }     

      createQuotation(input: {
        quotation: {
          id: "${idQuote}"
          nameClient: ${dataClient.nameClient !== '' ? `"${dataClient.nameClient.client}"` : `"${dataClient.newNameClient}"`}
          nameContact: ${dataClient.nameContact !== '' ? `"${dataClient.nameContact}"` : null}
          emailClient: ${dataClient.emailClient !== '' ? `"${dataClient.emailClient}"` : null}
          phoneClient: ${dataClient.phoneClient !== '' ? `"${dataClient.phoneClient.replace(/[()' '-]/g, '')}"` : null}
          nameEvent: "${dataClient.nameEvent.label}"
          place: "${dataClient.placeEvent.label}"
          quantityHours: ${dataClient.quantityHours}
          priceGain: ${totals.agency.gain}
          percentageGain: ${parametersQuotation.gain}
          priceAgentService: ${totals.parameters.totalAgentService}
          percentageAgentService: ${parametersQuotation.agentService ? (taxations.agentService ? taxations.agentService.price : 0) : 0}
          clientId: ${dataClient.nameClient === '' ? null : `"${dataClient.nameClient.value}"`}
          agencyId: "${idAgency}"
          cityId: ${Number(dataClient.cityEvent)}
          stateId: ${idState}
          subtotalItalentt: ${totals.italentt.subtotal}
          ivaItalentt: ${totals.italentt.iva}
          totalItalentt: ${totals.italentt.total}
          subtotal: ${totals.agency.subtotal}
          iva: ${totals.agency.iva}   
          total: ${totals.agency.total}
          code: "${processCode.codeQuotation}"
          startDate: ${dataClient.start ? `"${dataClient.start.toISOString()}"` : null}
          endDate: ${dataClient.end ? `"${dataClient.end.toISOString()}"` : null}
          foodTransportation: ${dataClient?.foodTransportation || null}
          feeding: ${dataClient?.foodTransportation ? (dataClient?.feeding === '' ? 0 : dataClient.feeding) : 0}
          transport: ${dataClient?.foodTransportation ? (dataClient?.transport === '' ? 0 : dataClient.transport) : 0}
          observation: "${dataClient?.observation || ''}"
          dialingCode: ${dataClient.dialingCode}
          currencyId: "${parametersQuotation.currency}"
        }
      }){
        quotation {
          id
          code
        }
      }

    ${talents.map((talent, index) => {
    return `createTypeTalent${index}: createQuotationTypeTalent(input: { 
          quotationTypeTalent: {
            id: "${talent.id}"
            profile: ${talent.type.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()}
            quantityDays: ${talent.quantityDays}
            budget: ${talent.budget}
            paymentTalentProducer: ${billsByTalent[index].producer.paymentPersonal}
            subtotalProducer: ${billsByTalent[index].producer.subtotal}
            ivaProducer: ${billsByTalent[index].producer.iva}
            totalProducer: ${billsByTalent[index].producer.total}
            paymentTalentClient: ${billsByTalent[index].client.paymentPersonal}
            subtotalClient: ${billsByTalent[index].client.subtotal}
            ivaClient: ${billsByTalent[index].client.iva}
            totalClient: ${billsByTalent[index].client.total}
            paymentTalentRetention: ${billsByTalent[index].platformItalentt.paymentTalentRetention}
            gainItalentt: ${billsByTalent[index].platformItalentt.gainItalentt}
            payPerDay: ${billsByTalent[index].platformItalentt.payPerDay}
            announcement: ${billsByTalent[index].platformItalentt.announcement}
            totalAnnouncement: ${billsByTalent[index].platformItalentt.totalAnnouncement}
            reteFuente: ${billsByTalent[index].platformItalentt.reteFuente}
            reteIca: ${billsByTalent[index].platformItalentt.reteIca}
            reteIva: ${billsByTalent[index].platformItalentt.reteIva}
            expectedPayment: ${billsByTalent[index].platformItalentt.expectedPayment}
            italentt: ${billsByTalent[index].platformItalentt.italentt}
            talentCosts: ${billsByTalent[index].platformItalentt.talentCosts}
            serviceAgentCost: ${billsByTalent[index].platformItalentt.serviceAgentCost}
            foodCost: ${billsByTalent[index].platformItalentt.foodCost}
            transportationCost: ${billsByTalent[index].platformItalentt.transportationCost}
            grossProfit: ${billsByTalent[index].platformItalentt.grossProfit}
            incomeTax: ${billsByTalent[index].platformItalentt.incomeTax}
            netIncome: ${billsByTalent[index].platformItalentt.netIncome}
            announcementAtt: ${billsByTalent[index].platformItalentt.announcementATT}
            quotationId: "${idQuote}"
          }
        }){
          quotationTypeTalent{
            id
          }
        }

        ${talent.genders.map((item, indexGender) => {
      let gender = "";
      if (item.gender.includes('femenino')) {
        gender = "FEMENINO"
      } else if (item.gender.includes('masculino')) {
        gender = "MASCULINO"
      }
      console.log("gender", gender);
      return `createGenders${index}${indexGender}: createTypeTalentGender(input: { 
                typeTalentGender: {
                  id: "${item.id}"
                  gender: ${gender}
                  quantity: ${item.quantity}
                  quotationTypeTalentId: "${talent.id}"
                }
              }){
                typeTalentGender{ 
                  id
                }
              }`
    })}
      `})}
    }
  `;

  let object = gql`${cadena}`;
  // console.log("cadena: ", cadena);
  return object;
}

const handleUpdateQuotation = (data, billsByTalent, totals, taxations, idQuote, idState) => {
  const { dataClient, parametersQuotation, talentsDelete, talentsCreate } = data;
  console.log("dataClient: ", data);
  let cadena = `
    mutation{
        updateQuotation(input: {
          id: "${idQuote}"
          patch: {
            ${dataClient.hasOwnProperty('foodTransportation') ? `foodTransportation: ${dataClient.foodTransportation}` : ''}
            ${dataClient.hasOwnProperty('nameClient') ? `nameClient: ${null}` : dataClient.hasOwnProperty('newNameClient') ? `nameClient: "${dataClient.newNameClient}"` : ''}
            ${dataClient.hasOwnProperty('nameClient') ? `nameContact: ${null}` : dataClient.hasOwnProperty('nameContact') ? `nameContact: "${dataClient.nameContact}"` : ''}
            ${dataClient.hasOwnProperty('nameClient') ? `emailClient: ${null}` : dataClient.hasOwnProperty('emailClient') ? `emailClient: "${dataClient.emailClient}"` : ''}
            ${dataClient.hasOwnProperty('nameClient') ? `phoneClient: ${null}` : dataClient.hasOwnProperty('phoneClient') ? `phoneClient: "${dataClient.phoneClient.replace(/[()' '-]/g, '')}"` : ''}
            ${dataClient.hasOwnProperty('nameClient') ? `clientId: "${dataClient.nameClient.value}"` : dataClient.hasOwnProperty('newNameClient') ? `clientId: ${null}` : ''}
            ${dataClient.hasOwnProperty('nameEvent') ? `nameEvent: "${dataClient.nameEvent.label}"` : ''}
            ${dataClient.hasOwnProperty('placeEvent') ? `place: "${dataClient.placeEvent.label}"` : ''}
            ${dataClient.hasOwnProperty('quantityHours') ? `quantityHours: ${dataClient.quantityHours}` : ''}
            ${Object.keys(totals).length !== 0 ? `priceGain: ${totals.agency.gain}` : ''}
            ${parametersQuotation.hasOwnProperty('gain') ? `percentageGain: ${parametersQuotation.gain}` : ''}
            ${parametersQuotation.hasOwnProperty('currency') && parametersQuotation.currency !== "" ? `currencyId: "${parametersQuotation.currency}"` : ''}
            ${Object.keys(totals).length !== 0 ? `priceAgentService: ${totals.parameters.totalAgentService}` : ''}
            ${parametersQuotation.hasOwnProperty('agentService') ? `percentageAgentService: ${parametersQuotation.agentService ? taxations.agenteservicio.price : 0}` : ''}
            ${dataClient.hasOwnProperty('cityEvent') ? `cityId: ${Number(dataClient.cityEvent)}` : ''}
            ${`subtotalItalentt: ${totals.italentt.subtotal}`}
            ${`ivaItalentt: ${totals.italentt.iva}`}
            ${`totalItalentt: ${totals.italentt.total}`}
            ${`subtotal: ${totals.agency.subtotal}`}
            ${`iva: ${totals.agency.iva}`}
            ${`total: ${totals.agency.total}`}

            ${dataClient.hasOwnProperty('start') ? `startDate: "${dataClient.start.toISOString()}"` : ''}
            ${dataClient.hasOwnProperty('end') ? `endDate: "${dataClient.end.toISOString()}"` : ''}

            ${dataClient.foodTransportation ? (dataClient.hasOwnProperty('feeding') ? `feeding: ${dataClient.feeding === '' ? 0 : dataClient.feeding} ` : '') : 'feeding: 0 '}
            ${dataClient.foodTransportation ? (dataClient.hasOwnProperty('transport') ? `transport: ${dataClient.transport === '' ? 0 : dataClient.transport} ` : '') : 'transport: 0 '}
            stateId: ${idState}
            ${dataClient.hasOwnProperty('observation') ? `observation: "${String(dataClient.observation)}"` : ''}
            ${dataClient.hasOwnProperty('dialingCode') ? `dialingCode: ${dataClient.dialingCode}` : ''}
          }
        }){
          quotation{
            id
            code
          }
        } 

      ${talentsDelete.map((talent, index) => `
        delete${index}: deleteQuotationTypeTalent(input: {
          id: "${talent.id}"
        }){
          quotationTypeTalent{
            id
          }
        }
      `)}

      ${talentsCreate.map((talent, index) => `
        createTypeTalent${index}: createQuotationTypeTalent(input: {
          quotationTypeTalent: {
            id: "${talent.id}"
            profile: ${talent.type.toUpperCase()}
            quantityDays: ${talent.quantityDays}
            budget: ${talent.budget}
            paymentTalentProducer: ${billsByTalent[index].producer.paymentPersonal}
            subtotalProducer: ${billsByTalent[index].producer.subtotal}
            ivaProducer: ${billsByTalent[index].producer.iva}
            totalProducer: ${billsByTalent[index].producer.total}
            paymentTalentClient: ${billsByTalent[index].client.paymentPersonal}
            subtotalClient: ${billsByTalent[index].client.subtotal}
            ivaClient: ${billsByTalent[index].client.iva}
            totalClient: ${billsByTalent[index].client.total}
            paymentTalentRetention: ${billsByTalent[index].platformItalentt.paymentTalentRetention}
            gainItalentt: ${billsByTalent[index].platformItalentt.gainItalentt}
            payPerDay: ${billsByTalent[index].platformItalentt.payPerDay}
            announcement: ${billsByTalent[index].platformItalentt.announcement}
            totalAnnouncement: ${billsByTalent[index].platformItalentt.totalAnnouncement}
            reteFuente: ${billsByTalent[index].platformItalentt.reteFuente}
            reteIca: ${billsByTalent[index].platformItalentt.reteIca}
            reteIva: ${billsByTalent[index].platformItalentt.reteIva}
            expectedPayment: ${billsByTalent[index].platformItalentt.expectedPayment}
            italentt: ${billsByTalent[index].platformItalentt.italentt}
            talentCosts: ${billsByTalent[index].platformItalentt.talentCosts}
            serviceAgentCost: ${billsByTalent[index].platformItalentt.serviceAgentCost}
            foodCost: ${billsByTalent[index].platformItalentt.foodCost}
            transportationCost: ${billsByTalent[index].platformItalentt.transportationCost}
            grossProfit: ${billsByTalent[index].platformItalentt.grossProfit}
            incomeTax: ${billsByTalent[index].platformItalentt.incomeTax}
            netIncome: ${billsByTalent[index].platformItalentt.netIncome}
            announcementAtt: ${billsByTalent[index].platformItalentt.announcementATT}
            quotationId: "${idQuote}"
          }
        }){
          quotationTypeTalent{
            id
          }
        }

    ${talent.genders.map((item, indexGender) => {
    let gender = null
    if (item.gender.includes('femenino')) {
      gender = "FEMENINO"
    } else if (item.gender.includes('masculino')) {
      gender = "MASCULINO"
    }

    return `createGender${index}${indexGender}: createTypeTalentGender(input: {
              typeTalentGender: {
                id: "${item.id}"
                gender: ${gender}
                quantity: ${item.quantity}
                quotationTypeTalentId: "${talent.id}"
              }
            }){
              typeTalentGender{
                id
              }
            }`
  })}  
      `)}
    }
  `

  console.log(cadena)
  let object = gql`${cadena}`;
  return object;
}

const UPDATE_QUOTE = gql`
  mutation($id: UUID!, $stateId: Int){
    updateQuotation(input: {
      id: $id
      patch: {
        stateId: $stateId
      }
    }){
      quotation{
        id
      }
    }
  }
`;

const SAVE_URL_CONTRACT = gql`
mutation($id: UUID!, $url: String){
  updateConvocated(input: {
    id: $id
    patch: {
      urlContract: $url
    }
  }){
    convocated{
      id
    }
  }
}
`

const handleCreateClientForQuote = async (dataClient, quotationSelected) => {
  return new Promise((resolve, reject) => {
    let cadena = `
      mutation{
        createClient(input: {
          client: {
            id: "${dataClient.id}"
            name: "${dataClient.name}"
            phone: "${dataClient.phone}"
            typeIdentificationId: 1
            identification: "${dataClient.identification}"
            cityId: ${dataClient.cityId}
            nameProducer: "${dataClient.nameProducer}"
            phoneProducer: "${dataClient.phoneProducer}"
            emailProducer: "${dataClient.emailProducer}"
            state: ${dataClient.state}
            dialingCode: ${dataClient.dialingCode}
          }
        }){
          client{
            id
          }
        }

        createAgencyClient(input: {
          agencyClient: {
            agencyId: "${dataClient.agencyId}"
            clientId: "${dataClient.id}"
          }
        }){
          client{
            id
          }
        }

        updateQuotation(input: {
          id: "${quotationSelected}"
          patch: {
            clientId: "${dataClient.id}"
          }
        }) {
          quotation {
            id
          }
        }

      }
    `

    let object = gql`${cadena}`;
    resolve(object);
  });
};

const CREATE_CLIENT_FOR_QUOTE = gql`
  mutation(
    $name: String!
    $phone: String!
    $typeIdentificationId: Int!
    $identification: String!
    $cityId: Int!
    $nameProducer: String!
    $phoneProducer: String!
    $emailProducer: String!
    $state: Int
  ){
    createClient(input: {
      client: {
        name: $name
        phone: $phone
        typeIdentificationId: $typeIdentificationId
        identification: $identification
        cityId: $cityId
        nameProducer: $nameProducer
        phoneProducer: $phoneProducer
        emailProducer: $emailProducer
        state: $state
      }
    }){
      client{
        id
      }
    }
}`;



const handleUpdateAgency = (values, stateUser) => {
  let cadena = `
    mutation {
      updateAgency( input :{
        patch:{
          name: "${values.name}"
          email: "${values.email}"
          phone: "${values.phone}"
          state: ${values.state || values.stateItalenttByState.id}
          gain: ${values.gain}
        },
        id: "${values.id}"
      }){
        clientMutationId
      }
      ${values.state ?
      `
        updateUser( input: {
          patch: {
            state: ${stateUser}
          }
          id: "${values.users.nodes[0].id}",
        }) {
          clientMutationId
        }
        `
      : ''}
    }
  `
  // console.log(cadena)
  let object = gql`${cadena}`;
  return object;
}

const handleUpdateTalent = (client) => {
  let cadena = `
  mutation {
    updateTalent(input: {
      patch: {
        name: "${client.name}",
        lastname: "${client.lastname}",
        email: "${client.email}",
        stateId:${client.stateId},
        statePhoto:${client.statePhoto},
        observation: "${client.observation}"
        phone: "${client.phone.replace(/[' '()-]/g, '')}"
      },
      id: "${client.id}"
    }){
      clientMutationId
    },
    ${client.images?.list?.map((dato, index) => `
      createService${index}: updateMultimedia(
        input: {
          patch: {
            stateId: ${dato.stateId}, 
            type: ${dato.type}, 
            observation: "${dato.observation}"
          },
          id: "${dato.id}"
        }
      ){
        clientMutationId
      }
    `)}
    ${client?.profiles?.list?.map((dato, index) => {
    if (dato?.id) { //actualizamos el registro
      return `
          updateProfile${index}:  updateProfileTalent(
            input: {
              patch:{
                profileUserTalentId: "${dato.profileUserTalentId}"
              },
              id: "${dato.id}"
            }
          ){
            clientMutationId
          }
        `
    } else if (dato?.profileUserTalentId) { //creamos nuevo registro
      return `
          createProfile${index}: createProfileTalent(
            input: {
              profileTalent: {
                talentId: "${client.id}",
                main: ${dato.main},
                profileUserTalentId: "${dato.profileUserTalentId}"
              }
            }
          ){
            clientMutationId
          }
        `
    }
  })}
  }`
  // console.log(cadena);
  let object = gql`${cadena}`;
  return object;
}

const handleDeleteTalent = (client) => {
  let cadena = `
  mutation{
    ${client.map((dato, index) =>`
      deleteProfile${index}: deleteProfileTalent(
        input: {id: "${dato}"}
      ) {
        clientMutationId
        deletedProfileTalentNodeId
      }
    `)}
  }     
  `
  let object = gql`${cadena}`;
  return object;
}

export {
  updatePassword,
  changePasswordTalent,
  createTalent,
  registerFast,
  CREATECLIENT,
  CREATESERVICESCLIENT,
  CREATEAGENCYCLIENT,
  UPDATE_STATE_CLIENT,
  handleUpdateClient,
  CREATE_CLIENT_FAST,
  CREATE_EVENT_DIRECTORY_WITH_PLACE,
  CREATE_EVENT_DIRECTORY,
  CREATE_EVENT,
  // CREATE_EVENT_AGENCY_CLIENT,
  CREATE_EVENT_PLACE,
  handleCreateEventPersonal,
  DELETE_EVENT_PERSONAL,
  UPDATE_EVENT_IN_QUOTATION,
  EDIT_EVENT_WITH_PLACE,
  EDIT_EVENT_CREATE_PLACE,
  CREATE_CONVOCATORY_EVENT,
  UPDATE_EVENT_STATE_AND_URL_KEY,
  UPDATE_FINAL_CLIENT,
  UPDATE_FINAL_CLIENT_LOGO,
  UPDATE_ANNOUNCEMENT,
  handleCreateConvocateds,
  CREATE_ANNOUNCEMENT_FAST,
  UPDATE_EVENT_FOR_ADDITIONAL_PROFILES,
  UPDATE_STATE_ANNOUNCEMENT,
  UPDATE_STATE_CONVOCATED,
  UPDATE_STATE_EVENT,
  UPDATE_QUOTATION_EVENT,
  CREATE_LEGAL_REPRESENTATIVE,
  UPDATE_AGENCY_LOCATION_USER_LEGAL,
  UPDATE_AGENCY_LOCATION_USER,
  handleChangeStatesConvocateds,
  handleCreateFinalCLient,
  handleCreateTaxationAgency,
  deleteAgencyPricesTaxation,
  handleCreateQuotation,
  handleUpdateQuotation,
  handleCreatePriceTaxation,
  SAVE_URL_CONTRACT,
  UPDATE_QUOTE,
  CREATE_CLIENT_FOR_QUOTE,
  handleCreateClientForQuote,
  handleUpdateTalent,
  handleDeleteTalent,
  handleUpdateAgency
}

