import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Button, Col, Input, InputGroupAddon, InputGroupText, Label, Row, InputGroup } from 'reactstrap';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import DefaultFooter from "../../../containers/DefaultLayout/DefaultFooter";
import { AppFooter, AppHeader } from '@coreui/react';
import MaskedInput from 'react-text-mask';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import noTrasnportation from '../../../assets/img/default/defaultTransportation.png';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import './Cabify.css';
import MapComponent from '../../../components/googleMaps/googleMaps';
import * as Yup from 'yup';

const LoadingSpinner = () => {
    const override = css`
        display: block;
        margin: 0 auto;
        color: #20a8d8;
        size: 100;
    `;

    return (
        <ClipLoader loading={true} css={override} />
    );
};

const CabifyView = (props) => {
    const isMounted = useRef(true);
    const mapRef = useRef();

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const [originAutocomplete, setOriginAutocomplete] = useState(null);
    const [destinyAutocomplete, setDestinyAutocomplete] = useState(null);
    const [showClearIcons, setShowClearIcons] = useState({
        name: false,
        phoneClient: false,
        origin: false,
        destiny: false,
        reasonJourney: false,
        messajeToDriver: false,
    });

    const {
        loading,
        initialValues,
        submit,
        message,
        eventValid,
        nameEvent,
        transportationCurse,
        cancelJourney,
        countries,
        datadriver,
        changepoints
    } = props;

    const clearInput = (setFieldValue, fieldName) => {
        setFieldValue(fieldName, '');
        if (mapRef.current) {
            mapRef.current.updateSettingOrigin(fieldName === 'origin.address');
        }
    };

    const handleInputChange = (handleChange, e) => {
        const { name, value } = e.target;
        handleChange(e);
        setShowClearIcons(prevState => ({
            ...prevState,
            [name]: value.length > 0
        }));
    };

    const handlePlaceSelect = (place, fieldName, setFieldValue) => {
        if (isMounted.current && place) {
            const address = place.formatted_address;
            const coordinates = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            };
            console.log("fieldName:", fieldName);

            setFieldValue(`${fieldName}.address`, address);
            setFieldValue(`${fieldName}.coordinates`, coordinates);

            setShowClearIcons(prevState => ({
                ...prevState,
                [fieldName]: true
            }));

            // Llama a setCoordinates en MapComponent para actualizar los puntos en el mapa
            if (mapRef.current && mapRef.current.setCoordinates) {
                mapRef.current.setCoordinates({
                    [fieldName]: coordinates,
                });
            }
        }
    };

    const cityBounds = mapRef.current?.cityBounds || null;

    return (
        <Fragment>
            <LoadScript googleMapsApiKey={process.env.REACT_APP_KEY_GOOGLE} libraries={["places"]}>
                <AppHeader style={{ display: "flex", justifyContent: "center" }}>
                    <Row style={{ width: "100%" }}>
                        <Col md={12}>
                            <p style={{ textAlign: "center", fontSize: "18px" }}>
                                <strong>
                                    Servicio de transporte Niriun by Cabify
                                </strong>
                            </p>
                        </Col>
                    </Row>
                </AppHeader>
                <div className="app">
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().required('El nombre es obligatorio'),
                                phoneClient: Yup.string().required('El teléfono es obligatorio'),
                                dialingCode: Yup.string().required('El código de marcado es obligatorio'),
                                origin: Yup.object().shape({
                                    address: Yup.string().required('La dirección de origen es obligatoria'),
                                    coordinates: Yup.object({
                                        lat: Yup.number().required('Las coordenadas son obligatorias'),
                                    }).required('Las coordenadas son obligatorias'),
                                }),
                                destiny: Yup.object().shape({
                                    address: Yup.string().required('La dirección de destino es obligatoria'),
                                    coordinates: Yup.object({
                                        lat: Yup.number().required('Las coordenadas son obligatorias'),
                                    }).required('Las coordenadas son obligatorias'),
                                }),
                                reasonJourney: Yup.string().required('El motivo del viaje es obligatorio'),
                                messajeToDriver: Yup.string(),
                            })}
                            onSubmit={async (values, { setSubmitting }) => {
                                try {
                                    await submit(values);
                                } catch (error) {
                                    console.log(error);
                                }
                                setSubmitting(false);
                            }}
                        >
                            {({ values, isSubmitting, handleSubmit, handleChange, handleBlur, setFieldValue }) => (
                                <Form onSubmit={handleSubmit} style={{ height: "93vh" }}>
                                    <Row className="margin-ajust cabify-container">
                                        {eventValid ? (
                                            <Row className="event-info-container container-cabify-row">
                                                <Col xl={4} md={6} sm={12} xs={12} className="i-cards card-overlay" style={{ position: "relative", zIndex: 2 }}>
                                                    {!datadriver?.journey_id ?

                                                        <Row>
                                                            <Col md={12} sm={12} className="mt-2">
                                                                <h5>Evento: {nameEvent}</h5>
                                                            </Col>

                                                            <Col md={12} sm={12} className="mt-2">
                                                                <Autocomplete
                                                                    onLoad={(autocomplete) => setOriginAutocomplete(autocomplete)}
                                                                    onPlaceChanged={() => handlePlaceSelect(originAutocomplete.getPlace(), 'origin')}
                                                                >
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="fa fa-user"></i>
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            type="text"
                                                                            name="name"
                                                                            value={values.name}
                                                                            onChange={(e) => handleInputChange(handleChange, e)}
                                                                            placeholder="Nombre pasajero"
                                                                            style={{ paddingRight: '40px' }}
                                                                            onFocus={() => setShowClearIcons(prevState => ({
                                                                                ...prevState,
                                                                                name: values.name.length > 0
                                                                            }))}
                                                                        />
                                                                        {showClearIcons.name && (
                                                                            <InputGroupAddon addonType="append" style={{ position: 'relative' }}>
                                                                                <button
                                                                                    type="button"
                                                                                    className='delete-data'
                                                                                    onClick={() => clearInput(setFieldValue, 'name')}
                                                                                >
                                                                                    <i className="fa fa-times" style={{ color: '#6c757d' }}></i>
                                                                                </button>
                                                                            </InputGroupAddon>
                                                                        )}
                                                                    </InputGroup>
                                                                </Autocomplete>
                                                                <ErrorMessage name="name">
                                                                    {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
                                                                </ErrorMessage>
                                                            </Col>

                                                            <Col md={12} sm={12} className="mt-2">
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <Input className="select-zone"
                                                                            type="select" name="dialingCode"
                                                                            value={values.dialingCode || '57'}
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}>
                                                                            {countries.map(code => (
                                                                                <option key={code.id} value={code.id}>+{code.id}</option>
                                                                            ))}
                                                                        </Input>
                                                                    </InputGroupAddon>
                                                                    <Field type="text" name="phoneClient" component="input"
                                                                        render={({ field }) => (
                                                                            <MaskedInput
                                                                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ...(new Array(5).fill(/\d/))]}
                                                                                {...field}
                                                                                placeholder="Celular"
                                                                                name="phoneClient"
                                                                                guide={false}
                                                                                autoComplete="off"
                                                                                className="form-control"
                                                                                value={values.phoneClient}
                                                                                onBlur={handleBlur}
                                                                                onChange={(e) => handleInputChange(handleChange, e)} />
                                                                        )}
                                                                    />
                                                                    {showClearIcons.phoneClient && (
                                                                        <InputGroupAddon addonType="append" style={{ position: 'relative' }}>
                                                                            <button className='delete-data'
                                                                                type="button"
                                                                                onClick={() => clearInput(setFieldValue, 'phoneClient')}
                                                                            >
                                                                                <i className="fa fa-times" style={{ color: '#6c757d' }}></i>
                                                                            </button>
                                                                        </InputGroupAddon>
                                                                    )}
                                                                </InputGroup>
                                                                <ErrorMessage name="phoneClient">
                                                                    {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
                                                                </ErrorMessage>
                                                            </Col>

                                                            <Col md={12} sm={12} className="no-padding">
                                                                <Row className="origin-destiny">
                                                                    <Col md={12} sm={12} className="mt-2">
                                                                        <Label className="label-vehicle">Origen: </Label>
                                                                        <Autocomplete
                                                                            onLoad={autocomplete => setOriginAutocomplete(autocomplete)}
                                                                            onPlaceChanged={() => handlePlaceSelect(originAutocomplete.getPlace(), 'origin', setFieldValue)}
                                                                            options={
                                                                                cityBounds
                                                                                    ? {
                                                                                        bounds: cityBounds, // Usa los límites solo si están definidos
                                                                                        strictBounds: true, // Restringir búsquedas a los límites
                                                                                    }
                                                                                    : {}
                                                                            }
                                                                        >
                                                                            <InputGroup>
                                                                                <InputGroupAddon addonType="prepend">
                                                                                    <InputGroupText>
                                                                                        <i className="fa fa-map-pin"></i>
                                                                                    </InputGroupText>
                                                                                </InputGroupAddon>
                                                                                <Input
                                                                                    type="text"
                                                                                    name="origin.address"
                                                                                    value={values.origin.address}
                                                                                    onChange={e => handleInputChange(handleChange, e)}
                                                                                    placeholder="Origen"
                                                                                    style={{ paddingRight: '40px' }}
                                                                                />
                                                                                {showClearIcons.origin && (
                                                                                    <InputGroupAddon addonType="append">
                                                                                        <button
                                                                                            type="button"
                                                                                            className='delete-data'
                                                                                            onClick={() => clearInput(setFieldValue, 'origin.address')}
                                                                                        >
                                                                                            <i className="fa fa-times" style={{ color: '#6c757d' }}></i>
                                                                                        </button>
                                                                                    </InputGroupAddon>
                                                                                )}
                                                                            </InputGroup>
                                                                        </Autocomplete>
                                                                        <ErrorMessage name="origin.address">
                                                                            {msg => <div className="field-error"><small className="form-text text-danger">{msg}</small></div>}
                                                                        </ErrorMessage>
                                                                    </Col>

                                                                    <Col md={12} sm={12} className="mt-2">
                                                                        <Label className="label-vehicle">Destino: </Label>
                                                                        <Autocomplete
                                                                            onLoad={autocomplete => setDestinyAutocomplete(autocomplete)}
                                                                            onPlaceChanged={() => handlePlaceSelect(destinyAutocomplete.getPlace(), 'destiny', setFieldValue)}
                                                                            options={
                                                                                cityBounds
                                                                                    ? {
                                                                                        bounds: cityBounds, // Usa los límites solo si están definidos
                                                                                        strictBounds: true, // Restringir búsquedas a los límites
                                                                                    }
                                                                                    : {}
                                                                            }
                                                                        >
                                                                            <InputGroup>
                                                                                <InputGroupAddon addonType="prepend">
                                                                                    <InputGroupText>
                                                                                        <i className="fa fa-map-marker"></i>
                                                                                    </InputGroupText>
                                                                                </InputGroupAddon>
                                                                                <Input
                                                                                    type="text"
                                                                                    name="destiny.address"
                                                                                    value={values.destiny.address}
                                                                                    onChange={e => handleInputChange(handleChange, e)}
                                                                                    placeholder="Destino"
                                                                                    style={{ paddingRight: '40px' }}
                                                                                />
                                                                                {showClearIcons.destiny && (
                                                                                    <InputGroupAddon addonType="append">
                                                                                        <button
                                                                                            type="button"
                                                                                            className='delete-data'
                                                                                            onClick={() => clearInput(setFieldValue, 'destiny.address')}
                                                                                        >
                                                                                            <i className="fa fa-times" style={{ color: '#6c757d' }}></i>
                                                                                        </button>
                                                                                    </InputGroupAddon>
                                                                                )}
                                                                            </InputGroup>
                                                                        </Autocomplete>
                                                                        <ErrorMessage name="destiny.address">
                                                                            {msg => <div className="field-error"><small className="form-text text-danger">{msg}</small></div>}
                                                                        </ErrorMessage>
                                                                    </Col>

                                                                    <Button
                                                                        disabled={isSubmitting}
                                                                        className='btn-row'
                                                                        onClick={() => {
                                                                            changepoints(values, setFieldValue);
                                                                            if (mapRef.current) {
                                                                                mapRef.current.invertCoordinates();
                                                                            }
                                                                        }}>
                                                                        <i className="fa fa-refresh custom-icon-cabify" aria-hidden="true"></i>
                                                                    </Button>

                                                                </Row>
                                                            </Col>

                                                            {
                                                                values.reasonJourney == '' ?
                                                                    <Col md={12} sm={12} className="mt-2">
                                                                        <InputGroup>
                                                                            <InputGroupAddon addonType="prepend">
                                                                                <InputGroupText>
                                                                                    <i className="fa fa-info"></i>
                                                                                </InputGroupText>
                                                                            </InputGroupAddon>
                                                                            <Input
                                                                                type="text"
                                                                                name="reasonJourney"
                                                                                value={values.reasonJourney}
                                                                                onChange={(e) => handleInputChange(handleChange, e)}
                                                                                placeholder="Motivo de viaje"
                                                                                style={{ paddingRight: '40px' }}
                                                                                onFocus={() => setShowClearIcons(prevState => ({
                                                                                    ...prevState,
                                                                                    reasonJourney: values.reasonJourney.length > 0
                                                                                }))}
                                                                            />
                                                                            {showClearIcons.reasonJourney && (
                                                                                <InputGroupAddon addonType="append" style={{ position: 'relative' }}>
                                                                                    <button
                                                                                        type="button"
                                                                                        className='delete-data'
                                                                                        onClick={() => clearInput(setFieldValue, 'reasonJourney')}
                                                                                    >
                                                                                        <i className="fa fa-times" style={{ color: '#6c757d' }}></i>
                                                                                    </button>
                                                                                </InputGroupAddon>
                                                                            )}
                                                                        </InputGroup>
                                                                        <ErrorMessage name="reasonJourney">
                                                                            {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
                                                                        </ErrorMessage>
                                                                    </Col>
                                                                    :
                                                                    ''
                                                            }

                                                            <Col md={12} sm={12} className="mt-2">
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>
                                                                            <i className="fa fa-commenting"></i>
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input
                                                                        type="text"
                                                                        name="messajeToDriver"
                                                                        value={values.messajeToDriver}
                                                                        onChange={(e) => handleInputChange(handleChange, e)}
                                                                        placeholder="Mensaje para el conductor"
                                                                        style={{ paddingRight: '40px' }}
                                                                        onFocus={() => setShowClearIcons(prevState => ({
                                                                            ...prevState,
                                                                            messajeToDriver: values.messajeToDriver.length > 0
                                                                        }))}
                                                                    />
                                                                    {showClearIcons.messajeToDriver && (
                                                                        <InputGroupAddon addonType="append" style={{ position: 'relative' }}>
                                                                            <button
                                                                                type="button"
                                                                                className='delete-data'
                                                                                onClick={() => clearInput(setFieldValue, 'messajeToDriver')}
                                                                            >
                                                                                <i className="fa fa-times" style={{ color: '#6c757d' }}></i>
                                                                            </button>
                                                                        </InputGroupAddon>
                                                                    )}
                                                                </InputGroup>
                                                                <ErrorMessage name="messajeToDriver">
                                                                    {msg => <div className="field-error"><small id="emailHelp" className="form-text text-danger">{msg}</small></div>}
                                                                </ErrorMessage>
                                                            </Col>

                                                            <Col md={12} sm={12} className="mt-2">
                                                                <Button type="submit" color="primary" disabled={isSubmitting} className='btn-cabify'
                                                                    style={{ cursor: isSubmitting ? 'not-allowed' : 'pointer' }}>
                                                                    {isSubmitting ? "Cargando..." : "Solicitar Transporte"}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        :

                                                        <Row>
                                                            <Col md={12} sm={12} className="mt-2">
                                                                <h5>Conductor</h5>
                                                            </Col>

                                                            <Col md={12} sm={12} className="mt-2">
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>
                                                                            <i className="fa fa-user"></i>
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input
                                                                        disabled={true}
                                                                        type="text"
                                                                        name="name"
                                                                        value={datadriver.driver.name}
                                                                        placeholder="Nombre conductor"
                                                                        style={{ paddingRight: '40px' }}
                                                                    />
                                                                </InputGroup>
                                                            </Col>

                                                            <Col md={12} sm={12} className="mt-2">
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <Input
                                                                            className="select-zone"
                                                                            disabled={true}
                                                                            type="select" name="dialingCode"
                                                                            value={values.dialingCode || '57'}
                                                                        >
                                                                            {countries.map(code => (
                                                                                <option key={code.id} value={code.id}>+{code.id}</option>
                                                                            ))}
                                                                        </Input>
                                                                    </InputGroupAddon>
                                                                    <Field type="text" name="phoneClient" component="input"
                                                                        render={({ field }) => (
                                                                            <MaskedInput
                                                                                disabled={true}
                                                                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ...(new Array(5).fill(/\d/))]}
                                                                                {...field}
                                                                                placeholder="Celular"
                                                                                name="phone_number"
                                                                                guide={false}
                                                                                autoComplete="off"
                                                                                className="form-control"
                                                                                value={datadriver.driver.phone_number}
                                                                            />
                                                                        )}
                                                                    />
                                                                </InputGroup>
                                                            </Col>

                                                            <Col md={12} sm={12} className="mt-3">
                                                                <h5 style={{ marginBottom: '0px' }}>Vehiculo</h5>
                                                            </Col>

                                                            <Col md={12} sm={12} className="mt-2">
                                                                <Label className="label-vehicle">Placa: </Label>
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>
                                                                            <i className="fa fa-info"></i>
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input
                                                                        disabled={true}
                                                                        type="text"
                                                                        name="plate"
                                                                        value={datadriver.vehicle.plate}
                                                                        placeholder="placa vehiculo"
                                                                        style={{ paddingRight: '40px' }}
                                                                    />
                                                                </InputGroup>
                                                            </Col>

                                                            <Col md={12} sm={12} className="mt-2">
                                                                <Label className="label-vehicle">Modelo: </Label>
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>
                                                                            <i className="fa fa-info"></i>
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input
                                                                        disabled={true}
                                                                        type="text"
                                                                        name="model"
                                                                        value={datadriver.vehicle.name}
                                                                        placeholder="Modelo del vehivulo"
                                                                        style={{ paddingRight: '40px' }}
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                            <Col md={12} sm={12} className="mt-2">
                                                                <Label className="label-vehicle">Color: </Label>
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>
                                                                            <i className="fa fa-info"></i>
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input
                                                                        disabled={true}
                                                                        type="text"
                                                                        name="color"
                                                                        value={datadriver.vehicle.color}
                                                                        placeholder="Color del vehiculo"
                                                                        style={{ paddingRight: '40px' }}
                                                                    />
                                                                </InputGroup>
                                                            </Col>
                                                            <Col md={12} sm={12} className="mt-2 d-flex justify-content-center align-items-center">
                                                                <div className="price-box">
                                                                    {/* <i className="fas fa-money-bill-wave"></i>  */}
                                                                    <h5 className="price-text">Total:</h5>
                                                                    <h5 className="price-text">$ {datadriver.waypoints.total}</h5>
                                                                </div>
                                                            </Col>
                                                            <Col md={12} sm={12} className="mt-2">
                                                                <Button color="danger" disabled={!transportationCurse} className='btn-cabify'
                                                                    onClick={() => cancelJourney()}
                                                                    style={{ cursor: !transportationCurse ? 'not-allowed' : 'pointer' }}>
                                                                    Cancelar Transporte
                                                                </Button>
                                                            </Col>

                                                        </Row>
                                                    }
                                                </Col>
                                                <Col md={12} className="i-cards full-screen-map" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 1 }}>
                                                    <MapComponent
                                                        datadriver={datadriver?.waypoints?.rows[0]?.loc || false}
                                                        disabled={datadriver?.vehicle ? true : false}
                                                        ref={mapRef}
                                                        onSelectCoordinates={({ origin, destiny }) => {
                                                            if (origin) {
                                                                setFieldValue("origin", origin);
                                                                setShowClearIcons(prevState => ({ ...prevState, origin: origin.address.length > 0 }));
                                                            }
                                                            if (destiny) {
                                                                setFieldValue("destiny", destiny);
                                                                setShowClearIcons(prevState => ({ ...prevState, destiny: destiny.address.length > 0 }));
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        ) : (
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                <img style={{ width: "200px" }} src={noTrasnportation} alt="" />
                                                <h2 style={{textAlign: 'center'}} className="text-muted">{message}</h2>
                                            </div>
                                        )}
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
            </LoadScript>
            <AppFooter className="app-footer">
                <DefaultFooter />
            </AppFooter>
        </Fragment>
    );
};

export default CabifyView;
