import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { GoogleMap, Marker, DirectionsRenderer } from '@react-google-maps/api';
import { Col } from 'reactstrap';

const MapComponent = React.forwardRef(({ onSelectCoordinates, formOrigin, formDestination, disabled, datadriver }, ref) => {
    const mapRef = useRef(null);
    const [markers, setMarkers] = React.useState({ origin: null, destiny: null });


    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [origin, setOrigin] = useState(null);
    const [destination, setDestination] = useState(null);
    const [originAddress, setOriginAddress] = useState('');
    const [destinationAddress, setDestinationAddress] = useState('');
    const [isSettingOrigin, setIsSettingOrigin] = useState(true);
    const [directions, setDirections] = useState(null);
    const [driverCoordinates, setDriverCoordinates] = useState(null);
    const [userLocation, setUserLocation] = useState(null); // Nueva variable para la ubicación del usuario
    const [cityBounds, setCityBounds] = useState(null);

    const setCoordinates = ({ origin, destiny }) => {
        console.log("Actualizando coordenadas en el mapa:", { origin, destiny });

        setMarkers((prevMarkers) => ({
            origin: origin ? new window.google.maps.LatLng(origin.lat, origin.lng) : prevMarkers.origin,
            destiny: destiny ? new window.google.maps.LatLng(destiny.lat, destiny.lng) : prevMarkers.destiny,
        }));
    
        if (origin) {
            setOrigin(origin); // Actualizar estado de origen
        }
        if (destiny) {
            setDestination(destiny); // Actualizar estado de destino
        }
    };

    // Exponer la función para que CabifyView pueda llamarla

    const updateSettingOrigin = (value) => {
        if (value) {
            setIsSettingOrigin(value);
            setOrigin(null);
            setOriginAddress('');
        } else {
            setDestination(null);
            setDestinationAddress(null);
        }
        setDirections(null);
    };

    useEffect(() => {
        let isMounted = true;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    if (isMounted) {
                        const userCoordinates = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        setCenter(userCoordinates);
                        setUserLocation(userCoordinates); // Establece la ubicación del usuario
                    }
                },
                () => {
                    console.warn("Error al obtener la ubicación del usuario.");
                }
            );
        }
        return () => { isMounted = false; };
    }, []);

    useEffect(() => {
        if (!formOrigin) {
            setOrigin(null);
            setOriginAddress('');
            setDirections(null);
        } else {
            setOrigin(formOrigin);
        }

        if (!formDestination) {
            setDestination(null);
            setDestinationAddress('');
            setDirections(null);
        } else {
            setDestination(formDestination);
        }
    }, [formOrigin, formDestination]);

    useEffect(() => {
        if (datadriver && Array.isArray(datadriver) && datadriver.length === 2) {
            const coordinates = { lat: datadriver[0], lng: datadriver[1] };
            setDriverCoordinates(coordinates);
            setCenter(coordinates); // Centra el mapa en las coordenadas del 'datadriver'
        } else {
            setDriverCoordinates(null); // Si 'datadriver' es false o no válido, resetea
        }
    }, [datadriver]);

    useEffect(() => {
        if (userLocation) {
            setCityBounds(getBoundsFromLocation(userLocation));
        }
    }, [userLocation]);

    const handleMapClick = (event) => {
        if (disabled || driverCoordinates) return; // Si está deshabilitado o hay driverCoordinates, no hacer nada

        const { latLng } = event;
        const coordinates = {
            lat: latLng.lat(),
            lng: latLng.lng(),
        };

        if (isSettingOrigin) {
            setOrigin(coordinates);
            setIsSettingOrigin(false);
            getGeocode(coordinates, setOriginAddress, (address) => {
                onSelectCoordinates({ origin: { coordinates, address } });
            });
        } else {
            setDestination(coordinates);
            getGeocode(coordinates, setDestinationAddress, (address) => {
                onSelectCoordinates({ destiny: { coordinates, address } });
            });
        }
    };

    const getGeocode = (coordinates, setAddress, callback) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: coordinates }, (results, status) => {
            if (status === 'OK' && results[0]) {
                const address = results[0].formatted_address;
                setAddress(address);
                callback(address);
            } else {
                console.error('Error al obtener la dirección:', status);
                callback('');
            }
        });
    };

    useImperativeHandle(ref, () => ({
        setCoordinates,
        updateSettingOrigin,
        cityBounds,
        invertCoordinates: () => {
            if (origin && destination) {
                const newOrigin = destination;
                const newDestination = origin;
                setOrigin(newOrigin);
                setDestination(newDestination);
                // Actualizar direcciones
                getGeocode(newOrigin, setOriginAddress, () => { });
                getGeocode(newDestination, setDestinationAddress, () => { });
            }
        },
    }));

    useEffect(() => {
        let isMounted = true;
        if (origin && destination) {
            const directionsService = new window.google.maps.DirectionsService();
            directionsService.route(
                {
                    origin,
                    destination,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                    if (isMounted && status === window.google.maps.DirectionsStatus.OK) {
                        setDirections(result);
                    } else {
                        console.error(`Error al obtener la ruta: ${status}`);
                    }
                }
            );
        }
        return () => { isMounted = false; };
    }, [origin, destination]);

    const mapOptions = {
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
    };

    // funcion para calcular los limites de la busqueda de direcciones:
    const getBoundsFromLocation = (location, margin = 0.1) => {
        const { lat, lng } = location;
        const southwest = {
            lat: lat - margin,
            lng: lng - margin,
        };
        const northeast = {
            lat: lat + margin,
            lng: lng + margin,
        };
        return new window.google.maps.LatLngBounds(southwest, northeast);
    };
    

    return (
        <Col md={12} className="i-cards full-screen-map" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 1 }}>
            <GoogleMap
                mapContainerStyle={{ width: '100%', height: '100%' }}
                center={center}
                zoom={12}
                options={mapOptions}
                onClick={handleMapClick}
                onLoad={(map) => (mapRef.current = map)}
            >
                {userLocation && <Marker position={userLocation} label="Tú" />} {/* Muestra la ubicación del usuario */}
                {driverCoordinates && <Marker position={driverCoordinates} label="Driver" />}
                {origin && <Marker position={origin} label="A" />}
                {destination && <Marker position={destination} label="B" />}
                {directions && <DirectionsRenderer directions={directions} />}
                {markers.origin && <Marker position={markers.origin} />}
                {markers.destiny && <Marker position={markers.destiny} />}
            </GoogleMap>
        </Col>
    );
});

export default MapComponent;