import axios from 'axios';

// const headers = {
//     accept: 'application/json',
//     'content-type': 'application/json',
//     Authorization: `Bearer n-5gtfelc9Oho6qoqshez1z8FxnEKL`
// };

class ServiceCabify {

    // codigo para autenticarnos, se hace al final
    autenticationCabify = async () => {
        const uuidCabify = process.env.REACT_APP_UUID_CABIFY;
        const secretCabify = process.env.REACT_APP_SECRET_CABIFY;

        try {
            const response = await axios.post(
                '/auth/api/authorization',
                null,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Accept: 'application/json'
                    },
                    params: {
                        grant_type: 'client_credentials',
                        client_id: uuidCabify,
                        client_secret: secretCabify
                    }
                }
            );

            const token = response.data.access_token;
            return token;
        } catch (error) {
            console.error("Error obteniendo el Bearer Token:", error);
        }

    }

    // servicio para crear un viaje
    createJourney = async (data, token) => {
        try {
            const response = await axios.post(
                '/api/v4/journey', // La ruta relativa usando el proxy configurado
                data,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log("llave secretaÑ ", response.data);
            return response.data;
        } catch (error) {
            console.error("Error creando el viaje:", error);
            throw error;
        }
    };

    // servicio para cancelar un viaje
    cancelJourney = async (token, id) => {
        try {
            const response = await axios.post(
                `/api/v4/journey/${id}/state`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log("cancelado: ", response.data);
            return response.data;
        } catch (error) {
            console.error("Error cancelando el viaje:", error);
            throw error;
        }
    };


    // Método para obtener detalles de un viaje (pendiente de implementación)
    getJourney = async (journeyId, requesterId, token) => {
        try {
            const response = await axios.get(
                `/api/v4/journey/${journeyId}/state`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    },
                    params: {
                        requester_id: requesterId
                    }
                }
            );
            
            console.log(response.data);
            return response.data;
        } catch (error) {
            console.error("Error obteniendo el estado del viaje:", error);
            throw error;
        }
    }
}

export default ServiceCabify;
